import React, { useRef, useEffect } from "react";
import { useLocation, Switch, Route } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import ReactGA from "react-ga";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";
import Partners from "./views/Partners";
import ReturnPolicy from "./views/ReturnPolicy";
import Contact from "./views/Contact";
import TermsOfService from "./views/TermsOfService";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute
            exact
            path="/partners"
            component={Partners}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/returnpolicy"
            component={ReturnPolicy}
            layout={ReturnPolicy}
          />
          <AppRoute
            exact
            path="/sales"
            component={TermsOfService}
            layout={TermsOfService}
          />
          <AppRoute
            exact
            path="/terms"
            component={TermsOfService}
            layout={TermsOfService}
          />
          <AppRoute
            exact
            path="/tos"
            component={TermsOfService}
            layout={TermsOfService}
          />
          {/* <AppRoute
            exact
            path='/contact'
            component={Contact}
            layout={LayoutDefault}
          /> */}
          {/* <Route
            exact
            path='/clientArea'
            component={() => {
              window.location.href = "https://eld.nwt.ai";
              return null;
            }}
          /> */}
          {/* <AppRoute
            exact
            path='/contact'
            component={Contact}
            layout={LayoutDefault}
          /> */}
        </Switch>
      )}
    />
  );
};

export default App;
